import {
  Connection,
  CreateStandardStripeSubscriptionResponse,
  CreateStripeCheckoutSessionRequest_SubscriptionType,
  CreateStripeCheckoutSessionResponse,
  Deductible,
  HealthProfileType,
  Member,
  MOOP,
  RegisterDemographicsRequest,
  RegisterHealthProfilesRequest,
  RegisterHealthProfilesResponse,
  RegisterMemberSourceRequest,
  RegisterMemberSourceResponse,
  RegisterPayerRequest,
  RegisterPayerResponse,
  RegisterReferralSourceRequest,
  RegisterReferralSourceResponse,
  RegisterUnsupportedCredentialsRequest,
  RegisterUnsupportedCredentialsResponse,
} from '@api/app/v1/app_pb';
import { PartialMessage, Timestamp } from '@bufbuild/protobuf';
import debounce from 'lodash.debounce';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

import { getFirebaseAuthToken } from '@/firebase/auth';
import useAppService from '@/hooks/useAppService';
import analytics from '@/segment';
import getFromLocalStorage from '@/utils/getFromLocalStorage';

import useSegmentAnalytics from './useSegmentAnalytics';

const useMemberData = (): {
  registerMemberDemographics: (
    data: RegisterMemberDemographicsData,
  ) => Promise<Member | ErrorResponse>;
  registerPayer: (
    data: RegisterPayerData,
  ) => Promise<RegisterPayerResponse | ErrorResponse>;
  registerUnsupportedCredentials: (
    data: RegisterUnsupportedCredentialsData,
  ) => Promise<RegisterUnsupportedCredentialsResponse | ErrorResponse>;
  registerReferralSource: (
    data: RegisterReferralSourceData,
  ) => Promise<RegisterReferralSourceResponse | ErrorResponse>;
  registerHealthProfiles: (
    data: RegisterHealthProfilesData,
  ) => Promise<RegisterHealthProfilesResponse | ErrorResponse>;
  registerMemberSource: (
    data: RegisterMemberSourceData,
  ) => Promise<RegisterMemberSourceResponse | ErrorResponse>;
  createCheckoutSession: (
    subscriptionType?: CreateStripeCheckoutSessionRequest_SubscriptionType,
    promotionCode?: string,
  ) => Promise<CreateStripeCheckoutSessionResponse | ErrorResponse>;
  createStandardSubscription: () => Promise<
    CreateStandardStripeSubscriptionResponse | ErrorResponse
  >;
  getMember: () => void;
  getInsuranceBuckets: () => Promise<void | ErrorResponse>;
  clearMemberData: () => void;
  refreshMemberData: () => Promise<void>;
  memberData: StoredMemberData;
  memberEvents: Event[];
  memberConnections: Connection[];
  amountSaved: number;
  insuranceBuckets: [Deductible[], MOOP[]];
  isLoading: boolean;
} => {
  const router = useRouter();
  const pathname = usePathname();
  const getClient = useAppService();
  const searchParams = useSearchParams();
  const { trackEvent } = useSegmentAnalytics();

  const [memberData, setMemberData] = useState({} as StoredMemberData);
  const [memberEvents] = useState([] as Event[]);
  const [memberConnections, setMemberConnections] = useState(
    [] as Connection[],
  );
  const [amountSaved, setAmountSaved] = useState(0);
  const [deductibles, setDeductibles] = useState([] as Deductible[]);
  const [moops, setMoops] = useState([] as MOOP[]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMemberData = async () => {
    Promise.all([
      getMember(),
      listConnections(),
      getInsuranceBuckets(),
      getAmountSaved(),
    ]).then(([member, connections]) => {
      if (member instanceof Member && Array.isArray(connections)) {
        redirectMember(member, connections);
      }
    });
  };

  const debouncedFetchMember = useMemo(
    () => debounce(fetchMemberData, 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    debouncedFetchMember();
  }, [debouncedFetchMember, pathname]);

  const clearMemberData = () => {
    setIsLoading(true);
    setMemberData({} as StoredMemberData);
  };

  const saveMemberAttribution = async () => {
    const source =
      searchParams.get('src') ||
      searchParams.get('utm_source') ||
      getFromLocalStorage('attributionSource');
    const attributionId =
      searchParams.get('gclid') || getFromLocalStorage('attributionId') || ''; // Google Ad Click ID
    const referrerUID =
      searchParams.get('referrerUID') || getFromLocalStorage('referrerUID');

    if (source) {
      await registerMemberSource({ source, attributionId });

      if (source === 'member_ref' && referrerUID) {
        await registerReferralSource({
          referralCategory: 'member_referral_link',
          referralNotes: referrerUID,
        });
      }
    }
  };

  const registerMemberDemographics = async (
    data: RegisterMemberDemographicsData,
  ) => {
    const token = await getFirebaseAuthToken();
    const formattedData = data as PartialMessage<RegisterDemographicsRequest>;

    if (token) {
      return await getClient()
        .then(client =>
          client.registerDemographics(formattedData, {
            headers: { Authorization: token },
          }),
        )
        .then(async response => {
          const member = response?.member;
          await saveMemberAttribution();
          setMemberData(
            existingMemberData =>
              ({
                ...existingMemberData,
                ...member,
                dateOfBirth: member?.dateOfBirth?.toDate(),
              }) as StoredMemberData,
          );
          return member as Member;
        })
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const registerPayer = async (data: RegisterPayerData) => {
    const token = await getFirebaseAuthToken();
    const formattedData = data as PartialMessage<RegisterPayerRequest>;

    if (token) {
      return await getClient()
        .then(client =>
          client.registerPayer(formattedData, {
            headers: { Authorization: token },
          }),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const registerUnsupportedCredentials = async (
    data: RegisterUnsupportedCredentialsData,
  ) => {
    const token = await getFirebaseAuthToken();
    const formattedData =
      data as PartialMessage<RegisterUnsupportedCredentialsRequest>;

    if (token) {
      return await getClient()
        .then(client =>
          client.registerUnsupportedCredentials(formattedData, {
            headers: { Authorization: token },
          }),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const registerMemberSource = async (data: RegisterMemberSourceData) => {
    const token = await getFirebaseAuthToken();
    const formattedData = data as PartialMessage<RegisterMemberSourceRequest>;

    if (token) {
      return await getClient()
        .then(client =>
          client.registerMemberSource(formattedData, {
            headers: { Authorization: token },
          }),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const registerReferralSource = async (data: RegisterReferralSourceData) => {
    const token = await getFirebaseAuthToken();
    const formattedData = data as PartialMessage<RegisterReferralSourceRequest>;

    if (token) {
      return await getClient()
        .then(client =>
          client.registerReferralSource(formattedData, {
            headers: { Authorization: token },
          }),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const registerHealthProfiles = async (data: RegisterHealthProfilesData) => {
    const token = await getFirebaseAuthToken();
    const formattedData = data as PartialMessage<RegisterHealthProfilesRequest>;

    if (token) {
      return await getClient()
        .then(client =>
          client.registerHealthProfiles(formattedData, {
            headers: { Authorization: token },
          }),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const getMember = async () => {
    const token = await getFirebaseAuthToken();
    const isInternalUser = getFromLocalStorage('internal_user');

    if (token) {
      return await getClient()
        .then(client =>
          client.getCurrentMember({}, { headers: { Authorization: token } }),
        )
        .then(response => {
          const member = response?.member;

          if (member?.id) analytics.identify(member.id);

          if (
            !isInternalUser &&
            member?.email &&
            member?.email.includes('sheerhealth')
          ) {
            trackEvent('internal_user');
            localStorage.setItem('internal_user', 'true');
          }

          setMemberData(
            existingMemberData =>
              ({
                ...existingMemberData,
                ...member,
                dateOfBirth: member?.dateOfBirth?.toDate(),
              }) as StoredMemberData,
          );

          return member as Member;
        })
        .catch(handleServerError);
    } else {
      setIsLoading(false);
    }
  };

  const getAmountSaved = async () => {
    const token = await getFirebaseAuthToken();

    if (token) {
      return await getClient()
        .then(client =>
          client.getAmountSaved({}, { headers: { Authorization: token } }),
        )
        .then(response => {
          const amountSaved = response?.amountSaved;
          setAmountSaved(amountSaved);
        })
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const listConnections = async () => {
    const token = await getFirebaseAuthToken();
    if (token) {
      return await getClient()
        .then(client =>
          client.listConnections({}, { headers: { Authorization: token } }),
        )
        .then(response => {
          setMemberConnections(response?.connections as Connection[]);
          return response?.connections as Connection[];
        })
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const createCheckoutSession = async (
    subscriptionType?: CreateStripeCheckoutSessionRequest_SubscriptionType,
    promotionCode?: string,
  ) => {
    const token = await getFirebaseAuthToken();
    if (token) {
      return await getClient()
        .then(client =>
          client.createStripeCheckoutSession(
            { subscriptionType, promotionCode },
            { headers: { Authorization: token } },
          ),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const createStandardSubscription = async () => {
    const token = await getFirebaseAuthToken();
    if (token) {
      return await getClient()
        .then(client =>
          client.createStandardStripeSubscription(
            {},
            { headers: { Authorization: token } },
          ),
        )
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const getInsuranceBuckets = async () => {
    const token = await getFirebaseAuthToken();

    if (token) {
      return await getClient()
        .then(client => {
          const deductiblesResponse = client.getDeductibles(
            {},
            { headers: { Authorization: token } },
          );
          const moopsResponse = client.getOutOfPocketMaximums(
            {},
            { headers: { Authorization: token } },
          );

          return Promise.all([deductiblesResponse, moopsResponse]);
        })
        .then(([deductiblesResponse, moopsResponse]) => {
          setDeductibles(deductiblesResponse?.deductibles as Deductible[]);
          setMoops(moopsResponse?.moops as MOOP[]);
          return;
        })
        .catch(handleServerError);
    } else {
      setIsLoading(false);
      return { error: errorConsts.loggedOut.client };
    }
  };

  const handleServerError = async (error: unknown) => {
    setIsLoading(false);

    if (error?.toString().includes(errorConsts.noMemberFound.server)) {
      return { error: errorConsts.noMemberFound.client } as ErrorResponse;
    } else {
      return { error: errorConsts.general.client } as ErrorResponse;
    }
  };

  const redirectMember = (member: Member, connections: Connection[]) => {
    const isRegistered = member.registered;
    const isOnboarded = connections.some(connection => !connection.inactive);

    const currentStep = {
      isRegistration: pathname.includes('registration'),
      isOnboarding:
        pathname.includes('onboarding') || pathname.includes('connect'),
      isLogin: pathname.includes('login'),
    };

    const redirectPath = getRedirectPath(
      isRegistered,
      isOnboarded,
      currentStep,
    );

    if (redirectPath) {
      router.push(redirectPath);
    } else if (isLoading) {
      setIsLoading(false);
    }
  };

  const getRedirectPath = (
    memberIsRegistered: boolean,
    memberIsOnboarded: boolean,
    currentStep: {
      isRegistration: boolean;
      isOnboarding: boolean;
      isLogin: boolean;
    },
  ) => {
    if (memberIsRegistered && memberIsOnboarded && currentStep.isRegistration) {
      trackEvent('redirect_to_home');
      return '/home';
    }
    if (memberIsRegistered && !memberIsOnboarded && !currentStep.isOnboarding) {
      trackEvent('redirect_to_onboarding');
      return '/onboarding';
    }
    if (
      !memberIsRegistered &&
      !currentStep.isLogin &&
      !currentStep.isRegistration
    ) {
      trackEvent('redirect_to_registration');
      return '/registration/contact';
    }
  };

  return {
    registerMemberDemographics,
    registerPayer,
    registerUnsupportedCredentials,
    registerReferralSource,
    registerHealthProfiles,
    registerMemberSource,
    getMember,
    getInsuranceBuckets,
    createCheckoutSession,
    createStandardSubscription,
    clearMemberData,
    refreshMemberData: fetchMemberData,
    memberData,
    memberEvents,
    memberConnections,
    amountSaved,
    isLoading,
    insuranceBuckets: [deductibles, moops],
  };
};

export default useMemberData;

export const errorConsts = {
  noMemberFound: {
    server: 'no member found',
    client:
      "We couldn't find your profile. Please confirm you are using the correct email, or complete your registration.",
  },
  general: {
    client: 'Something went wrong on our end. Please try again later.',
  },
  loggedOut: {
    client: 'Please log back in to continue.',
  },
};

export interface ErrorResponse {
  error: string;
}

interface StoredMemberData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  dateOfBirth: Date;
  insurance?: {
    name: string;
    isPrimaryMember: boolean;
  };
  registered?: boolean;
  amountSaved?: number;
}

interface RegisterMemberDemographicsData {
  firstName?: boolean;
  lastName?: string;
  phone?: string;
  dateOfBirth?: Timestamp;
}

interface RegisterHealthProfilesData {
  healthProfiles: HealthProfileType[];
}

interface RegisterPayerData {
  payerName: string;
  primary?: boolean;
  primaryFirstName?: string;
  primaryLastName?: string;
  primaryDateOfBirth?: Timestamp;
}

interface RegisterUnsupportedCredentialsData {
  connectionId: string;
  username: string;
  password: string;
}

interface RegisterMemberSourceData {
  source: string;
  attributionId?: string;
}

interface RegisterReferralSourceData {
  referralCategory: string;
  referralNotes: string;
}
